/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/
import { OBSERVER } from './../main.js'

// Bannière d'accueil
export const slickHomeBanner = () => {
  $('.js-home-banner-slider-background').slick({
      dots: false,
      arrows: false,
      speed: 600,
      fade: true,
    })
    
    $('.js-home-banner-slider-text').slick({
      arrows: false,
      dots: true,
      appendDots: '.js-home-banner-slider-dots',
      speed: 600,
      fade: true,
      asNavFor: '.js-home-banner-slider-background',
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: true,
  })

  function pauseSlick() {
    $('.js-home-banner-slider-text').slick('slickPause');
  }

  function playSlick() {
    $('.js-home-banner-slider-text').slick('slickPlay');
  }

  OBSERVER.add({
    name: 'slick-pause-and-play',
    event: 'focus',
    target: '#form-search-input-banner',
    function: pauseSlick
  })

  OBSERVER.add({
    name: 'slick-pause-and-play',
    event: 'focusout',
    target: '#form-search-input-banner',
    function: playSlick
  })

  OBSERVER.on('slick-pause-and-play')

  checkBtnExistence(0)

  $('.js-home-banner-slider-text').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    checkBtnExistence(nextSlide)
  })

  function checkBtnExistence(nextSlide) {
    if($(`.js-home-banner-slider-text [data-slick-index="${nextSlide}"] .btn`).length) {
      $('.home-banner__form-container').addClass('home-banner__form-container--when-btn-is-here')
    } else {
      $('.home-banner__form-container').removeClass('home-banner__form-container--when-btn-is-here')
    }
  }

  let vid = document.querySelector('.js-video-autoplay')
  if(vid){
    if(window.innerWidth >= 900)
      vid.play()
  }
}

// Liens rapides (page d'accueil)
export const slickHomeFastLinks = (root = document) => {
    $(root).find('.js-home-fastlinks-slider').slick({
        arrows: false,
        speed: 600,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false,
    })

    $(root).find('.js-home-fastlinks-slider-prev').on('click', () => {
        $(root).find('.js-home-fastlinks-slider').slick('slickPrev')
    })
    
    $(root).find('.js-home-fastlinks-slider-next').on('click', () => {
        $(root).find('.js-home-fastlinks-slider').slick('slickNext')
    })
}

// Liens rapides (autres pages)
export const slickFastLinks = (root = document) => {
  $(root).find('.js-overlay-fastlinks-slider').slick({
      arrows: false,
      speed: 600,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
  })

  $(root).find('.js-overlay-fastlinks-slider-prev').on('click', () => {
      $(root).find('.js-overlay-fastlinks-slider').slick('slickPrev')
  })
  
  $(root).find('.js-overlay-fastlinks-slider-next').on('click', () => {
      $(root).find('.js-overlay-fastlinks-slider').slick('slickNext')
  })
}

// Évènements similaires
export const slickSimilarEvents = (root = document) => {
  let total = $(root).find('.js-similar-events-slider .similar-events__slider-slide:not(.slick-cloned)').length

  if(total < 3) {
    $(root).find('.js-similar-events-slider').slick({
      arrows: false,
      speed: 600,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      infinite: false,
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            swipeToSlide: true
          }
        }
      ]

  
    })
  } else {
    $(root).find('.js-similar-events-slider').slick({
        arrows: false,
        speed: 600,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false,
        infinite: true,
    })
  }

    $(root).find('.js-similar-events-slider-prev').on('click', () => {
        $(root).find('.js-similar-events-slider').slick('slickPrev')
    })
    
    $(root).find('.js-similar-events-slider-next').on('click', () => {
        $(root).find('.js-similar-events-slider').slick('slickNext')
    })
}

// Alertes
export function slickAlerts(root = document) {
    if (root.querySelectorAll('.js-alerts-slider').length != 0) {
      $(root).find('.js-alerts-slider').slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        draggable: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
      })
  
      let total = $(root).find('.js-alerts-slider .slick-slide:not(.slick-cloned)').length
      let displayedTotal = total < 10 ? + total : total
      $(root).find('.js-alerts-total').html(displayedTotal)
  
      $(root).find('.js-alerts-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
        $(root).find('.js-alerts-current').html(slidePosition)
      })
  
      $(root).find('.js-alerts-slider-prev').on('click', () => {
        $(root).find('.js-alerts-slider').slick('slickPrev')
      })
    
      $(root).find('.js-alerts-slider-next').on('click', () => {
        $(root).find('.js-alerts-slider').slick('slickNext')
      })
    }
}
  
// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, totalElement)  {
  $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $(currentElement).html(slidePosition)
  })

  let total = $(slickElement + ' .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total < 10 ? '0' + total : total
  $(totalElement).html(displayedTotal)
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(root = document) {
    $(root).find('.slick-initialized').slick('unslick') 
}