/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

// import $ from 'jquery'
import anime from 'animejs/lib/anime.es'
import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { Accordions } from '../classes/Accordions.js'
import { copyTextToClipboard } from './functions.js'

// Fonction gérant l'overlay Dropdown
export const overlayDropdown = (root = document) => {
    let dropdown = new Overlay({
      name: 'dropdown',
      create: { close: false },
      click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown', switch: '.js-toggle-overlay-search, .js-toggle-overlay-fastlinks' }},
      animations: {
        addTransition: true,
        styles: [{ property: 'height', value: 'dataset', easing: 'easeInOutQuart' }]
      },
      options: { 
        speed: 800,
        root: root
      }
    })
  
    dropdown.init()
  
    // Ajuster la taille de chaque sous-section pour éviter les bugs lorsque la fenêtre est redimensionnée
    const setHeightSubSections = () => {
      let i, j, offsetHeight = 0, height = 0
      const section = root.querySelectorAll('.js-dropdown-section')
      const sectionLength = section.length
    
      for (i=0; i<sectionLength; i++) {
        const subSection = section[i].children
        const subSectionLength = subSection.length
        
        for (j=0; j<subSectionLength; j++) {
          subSection[j].style.height = ''
          offsetHeight = subSection[j].offsetHeight
          if (offsetHeight > height)
            height = offsetHeight
        }
    
        for (j=0; j<subSectionLength; j++) 
          subSection[j].style.height = `${height}px`
    
        height = 0
        offsetHeight = 0
      }
    }
  
    setHeightSubSections()
  
    OBSERVER.add({ name: 'setHeightSubSections', event: 'resize', function: setHeightSubSections, target: 'window', root: root })
    OBSERVER.on('setHeightSubSections')
}

// Fonction gérant l'overlay Menu
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-menu' }},
    options: {
      speed: 800,
      goToSelector: 'html, body', 
      root: root
    }
  })
  
  menu.init()

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
    root: root
  })

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
    root: root
  })

  const onClose = () => {
    mainLevel.closeAll()
    secondLevel.closeAll()
  }

  const onCloseMainLevel = () => {
    secondLevel.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAllAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.on('overlayMenu')
}

// Fonction gérant l'overlay Search
export const overlaySearch = (root = document) => {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { openComplete: true, closeComplete: true, },
    click: { 
      buttons: { 
        open: '[data-js="open-overlay-search"]', 
        switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks', 
        close: '.js-close-overlay-search' 
      }
    },
    animations: {
      selector: '.overlay-search__container',
      styles: [{ 
        property: 'height', 
        value: '100%', 
        easing: 'easeInOutQuart' 
      }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false // Empêche l'overlay de se fermer tout seul en mobile lorsque le clavier du mobile s'ouvre
    }
  })

  search.init()

  const onFocus = () => {
    document.getElementById('form-search-input').focus();
  };

  const inputSearch = document.querySelector('.overlay-search [data-input="overlay-search-input"]');
  const inputValue = inputSearch.parentNode;
  const popularSearchElement = document.querySelector('.js-popular-search');
  const fastResultsElement = document.querySelector('.js-fast-results');

  const onCloseComplete = () => {
    inputSearch.value = '';
    inputValue.setAttribute('data-inputvalue', '');
    hideAutocomplete(popularSearchElement, fastResultsElement);
  };

  const html = document.querySelector('html');

  let ajaxRequest = false;
  const onInput = (e) => {

    if (ajaxRequest) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort();
      if (html.dataset.section !== 'suggestions') {
        search.trigger('suggestions');
      }
    }

    if (e.currentTarget.value.length >= 3) {
      ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
        data: { q: e.currentTarget.value },
        update: { 'rechercheAutocomplete::autocomplete': '#overlay-search #autocomplete' },
        complete(data) {
          // Results loaded.
          ajaxRequest = false;

          // On affiche l'overlay des suggestions juste si ya des résultats
          if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
            if (html.dataset.section !== 'fast-results') {
              showAutocomplete(popularSearchElement, fastResultsElement);
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (html.dataset.section !== 'suggestions') {
              hideAutocomplete(popularSearchElement, fastResultsElement);
            }
          }
        },
      });

    } else {
      hideAutocomplete(popularSearchElement, fastResultsElement);
    }
  };

  const onClearInput = () => {
    hideAutocomplete(popularSearchElement, fastResultsElement);
  };

  OBSERVER.add({
    name: 'overlaySearch',
    event: 'onCloseCompleteOverlaySearch',
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    event: 'onOpenCompleteOverlaySearch',
    function: onFocus,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    event: 'input',
    function: onInput,
    target: '#form-search-input',
  });
  OBSERVER.add({
    name: 'overlaySearch',
    event: 'click',
    target: '.js-clear-input',
    function: onClearInput,
  });
  OBSERVER.on('overlaySearch');
}

function showAutocomplete(popularSearchElement, fastResultsElement) {
  anime({
    targets: fastResultsElement,
    duration: '800ms',
    easing: 'easeOutCubic',
    height: fastResultsElement.children[0].offsetHeight,
    translateY: {
      value: 0,
      delay: 300,
    },
    opacity: {
      value: 1,
      delay: 300,
    },
  });
  anime({
    targets: popularSearchElement,
    duration: '800ms',
    easing: 'easeOutCubic',
    height: 0,
    translateY: 50,
    opacity: 0,
  });
}

function hideAutocomplete(popularSearchElement, fastResultsElement) {
  anime({
    targets: fastResultsElement,
    duration: '800ms',
    easing: 'easeOutCubic',
    height: 0,
    translateY: 25,
    opacity: 0,
  });
  anime({
    targets: popularSearchElement,
    duration: '800ms',
    easing: 'easeOutCubic',
    height: popularSearchElement.children[0].offsetHeight,
    translateY: 0,
    opacity: 1,
  });
}

// Fonction gérant l'overlay Fastlinks
export const overlayFastlinks = (root = document) => {
  let fastlinks = new Overlay({
    name: 'Fastlinks',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-fastlinks', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-search', close: '.js-close-overlay-fastlinks' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
    }
  })

  fastlinks.init()
}

// Fonction gérant l'overlay popup
export function overlayPopup(root = document) {
  let popup = new Overlay({
    name: 'popup',
    create: {
      background: false
    },
    timeout: {
      delay: 300,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  popup.init()
}

// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  share.init()
  
  $(root).find('#copyShareBtn').click( function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $(root).find('.rubric-share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    //pour le partage par courriel
    $(root).find('#emailShareBtn').attr('href', 'mailto:?Subject=Ville de Cowansville&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $(root).find('#facebookShareBtn').attr('data-url', window.location.href)
    $(root).find('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $(root).find('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $(root).find('#emailShareBtn').attr('href', 'mailto:?Subject=Ville de Cowansville&body=' + window.location.href)
    $(root).find('#facebookShareBtn').attr('data-url', window.location.href)
    $(root).find('#twitterShareBtn').attr('data-url', window.location.href)
  })
}

// Fonction gérant l'overlay de calendrier
export function overlayCalendar(root = document) {
  let calendar = new Overlay({
    name: 'calendar',
    create: {
      background: false
    },
    click: {
      buttons: {
        open: '.js-open-overlay-calendar',
        close: '.js-close-overlay-calendar',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  calendar.init()
}
  