/*
|--------------------------------------------------------------------------
| Helper
|--------------------------------------------------------------------------
|
| Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
*/

import { OBSERVER } from "../main"

// Retourne si l'utilisateur est sur mobile ou pas
export const isMobile = () => {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}

// Retourne la position d'un élément dans le DOM
export const getElementOffset = (options) => {
  options.root === undefined ? options.root = document : options.root
  let box = typeof options.element === 'string' ? options.root.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

// Retourne le nombre d'octets formaté
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function forceBlankOnExterneAndPdfLinks() {
  let i,
    anchors = document.querySelectorAll('a[href]'),
    anchorsLength = anchors.length
  for (i=0; i<anchorsLength; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol) {
        anchors[i].setAttribute('target', '_blank')
      }
    }
  }
}

export function isVisible(element) {
  const rect = element.getBoundingClientRect()

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth  || document.documentElement.clientWidth

  const top = rect.top <= viewportHeight
  const right = rect.right >= 0 - viewportWidth
  const bottom = rect.bottom >= 0 - viewportHeight
  const left = rect.left <= viewportWidth

  return top && right && bottom && left
}

// Permet de donner la hauteur exact en mobile de 100vh
export function hundredVH() {
  let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  OBSERVER.add({ name:'hundredVH', event:'resize', function:setHeight, target: 'window' })
  OBSERVER.on('hundredVH')
  setHeight()
  function setHeight() {
    if (ww != (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)) {
      ww =  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}

export function sizeH1() {
  let i
  let h1 = document.querySelectorAll('h1, .h1')
  let h1Length = h1.length

  for(i=0; i<h1Length; i++) {
    if(h1[i].textContent.length > 35) {
      h1[i].classList.add('smaller')
    }
  }
}

// export function sizeTitle() {
//   let i
//   let h2 = document.querySelectorAll('.showcase__title')
//   let h2Length = h2.length

//   for(i=0; i<h2Length; i++) {
//     if(h2[i].textContent.length > 35) {
//       h2[i].classList.add('smaller')
//     }
//   } 
// }
