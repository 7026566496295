/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

import '@fancyapps/fancybox/dist/jquery.fancybox.js'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER, SCROLLFIRE } from './../main.js'
import { getElementOffset, isVisible } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
    root: options.root
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })
  
  return animation.finished
}

// Fonction gérant les alertes
export function alerts(root = document) {
  const onClick = () => root.querySelector('html').classList.remove('show-alerts')

  OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alerts-close',  root: root })
  OBSERVER.on('alerts')
}

// Fonction permettant d'activer le calendrier
export const calendar = (root = document) => {
  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')
  
  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ barba.go('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)
}

export const initBackToTop = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  
  OBSERVER.add({
    name: 'manageBackToTop',
    event: 'scroll',
    root: options.root,
    function: manageBackToTop
  })

  OBSERVER.add({
    name: 'manageBackToTop',
    event: 'resize',
    root: options.root,
    function: onResize
  })

  onResize()
  manageBackToTop()

  OBSERVER.on('manageBackToTop')
}

function onResize() {
  if(window.innerWidth > 1300) {
    clickToScrollToBlock({ selector: '.js-back-to-top', scrollTo: '.header' })
  } else {
    clickToScrollToBlock({ selector: '.js-back-to-top', scrollTo: '.header-mobile' })
  }
}

function manageBackToTop(){
  let headerVisible = document.documentElement.scrollTop <= 600;
  if(!headerVisible){
    document.querySelector('html').classList.add("show-back-to-top")
  }else{
    document.querySelector('html').classList.remove("show-back-to-top")
  }
}

export function copyTextToClipboard(text) {
  // if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  // }
  // navigator.clipboard.writeText(text).then(function() {
  //   console.log('Async: Copying to clipboard was successful!')
  // }, function(err) {
  //   console.error('Async: Could not copy text: ', err)
  // })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}


// Function permettant de savoir si les components sont vides
export function checkInsideComponent() {
  let containers = document.querySelectorAll('.components')

  if(containers.length > 0) {
    let i
    for(i = 0; i < containers.length; i++) {
      if(containers[i].children.length == 0) {
        containers[i].remove()
      }
    }
  }
}


// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {
  let themePath = window.config.theme_path

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    if (!link.querySelector('img')) {      
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-external')
      svgElem.appendChild(useElem);
      link.appendChild(svgElem)
    }
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    if (!link.querySelector('img')) {
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-download')
      svgElem.appendChild(useElem);
      link.appendChild(svgElem)
    }
  })

}